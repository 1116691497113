export function useFormattedText() {
  const { n } = useI18n()
  const storeStore = useStoreStore()

  const getMoneyFormattedText = (amount: number) => {
    const amountWithRate = +amount * storeStore.currencyRate
    return n(amountWithRate, {
      key: 'currency',
      currency: storeStore.getCurrencyCode
    })
  }

  const getRewardFormattedText = (rewardText: string, amount: number, key: string) => {
    return rewardText.replace(`{{${key}}}`, getMoneyFormattedText(amount))
  }

  return { getMoneyFormattedText, getRewardFormattedText }
}
